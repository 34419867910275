// import logo from './logo.svg';
// import './App.css';
import Logo from "./images/rapmarketcap-logo2.png";
import ImgHeader from "./images/gucci-mane-home.jpg";

function App() {
  return (
        <div class="w-screen h-full min-h-screen bg-gradient-to-tl from-blue-990 to-blue-910">
            <div class="flex items-center pt-6 ml-4 md:pt-10 md:ml-10">
              <img src={Logo} alt="Logo" class="h-6 w-6 md:h-12 md:w-12" />
              <p class="text-white text-1xl ml-2 md:text-3xl md:ml-5 font-MontserratAlternates font-semibold">Rap Market Cap</p>
            </div>
        
            <div className="relative overflow-hidden">
              <div className="max-w-7xl mx-auto">
                <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                  <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">

                    <div className="sm:text-center lg:text-left">
                      <h1 className="text-4xl tracking-tight font-MontserratAlternates font-extrabold text-white sm:text-5xl md:text-6xl">
                        <span className="block xl:inline">Does rappers have</span>{' '}
                        <span className="block text-green-400 xl:inline">strong fanbase ?</span>
                      </h1>
                      <p className="mt-3 font-MontserratAlternates text-base text-gray-300 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                        Game is over ! Let's determine who is the rap god, each token represent an internationnal rapper.
                        50% of supply go for the artists. There is no team wallet or transaction fees, it's a decentralised and community driven project.
                      </p>
                      <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                        <div className="rounded-md shadow">
                          <a
                            href="https://wallet.matic.network/bridge/"
                            target="_blank"
                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                          >
                            On Polygon (MATIC)
                          </a>
                        </div>
                        <div className="mt-3 sm:mt-0 sm:ml-3">
                          <a
                            href="https://quickchart.app/"
                            target="_blank"
                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                          >
                            See Charts
                          </a>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <img
                className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full rounded-l-full opacity-90"
                src={ImgHeader}
                alt=""
              />
            </div>
          </div>








            <div class="ml-4 mr-4 md:ml-28">
              <table class="table-fixed w-full md:w-1/2 mt-10">
                <thead>
                  <tr>
                    <th class="w-1/6 font-MontserratAlternates text-gray-400 text-left font-normal">Rank</th>
                    <th class="w-1/3 font-MontserratAlternates text-gray-400 text-left font-normal">Token</th>
                    <th class="w-1/5 font-MontserratAlternates text-gray-400 text-left font-normal">Price</th>
                    <th class="w-1/5 font-MontserratAlternates text-gray-400 text-left font-normal">Ch. 24h</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#1</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">Booba</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#2</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">Nekfeu</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#3</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">PNL</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#4</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">JUL</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#5</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">Niska</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#6</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">VALD</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#7</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">GIMS</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#6</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">Damso</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#7</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">Ninho</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#8</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">Rohff</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#9</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">Kaaris</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#10</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">Alonzo</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#11</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">KobaLaD</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#12</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">Alkpote</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                  <tr class="border-t-2 border-b-2 border-blue-920">
                    <td class="font-MontserratAlternates text-white font-light">#13</td>
                    <td class="font-MontserratAlternates text-white font-light uppercase">LaFouine</td>
                    <td class="font-MontserratAlternates text-white font-normal">$1.65</td>
                    <td class="font-MontserratAlternates text-green-400 font-light">+3.51%</td>
                  </tr>
                </tbody>
                </table>
              </div>
        </div>
  );
}

export default App;
